<template>
  <!-- Page Content  -->
  <b-container  fluid>
    <b-row>
      <b-col md="6" lg="3">
        <iq-card bodyClass="relative-background">
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div class="rounded-circle iq-card-icon iq-bg-primary mr-3"> <i class="ri-guide-line"></i></div>
              <div class="text-left">
                <h4 class="">{{ numberOfBookings }}</h4>
              </div>
            </div>
            <p class=" mb-0 mt-3">{{ $t('dashboard.bookings_this_month') }}</p>
            <div class="background-image">
              <img src="../../assets/images/page-img/36.png" class="img-fluid">
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card body-class="relative-background">
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div class="rounded-circle iq-card-icon iq-bg-warning mr-3"> <i class="ri-bank-card-fill"></i></div>
              <div class="text-left">
                <h4 class="">{{ totalCard }}</h4>
              </div>
            </div>
            <p class=" mb-0 mt-3">{{ $t('dashboard.online_this_month') }}</p>
            <div class="background-image">
              <img src="../../assets/images/page-img/37.png" class="img-fluid">
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card body-class="relative-background">
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div class="rounded-circle iq-card-icon iq-bg-danger mr-3"> <i class="ri-money-euro-circle-line"></i></div>
              <div class="text-left">
                <h4 class="">{{ totalCash }}</h4>
              </div>
            </div>
            <p class=" mb-0 mt-3">{{ $t('dashboard.cash_this_month') }}</p>
            <div class="background-image">
              <img src="../../assets/images/page-img/38.png" class="img-fluid">
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card body-class="relative-background">
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div class="rounded-circle iq-card-icon iq-bg-info mr-3"> <i class="ri-numbers-line"></i></div>
              <div class="text-left">
                <h4 class="">{{ totalMoney }}</h4>
              </div>
            </div>
            <p class=" mb-0 mt-3">{{ $t('dashboard.total_this_month') }}</p>
            <div class="background-image">
              <img src="../../assets/images/page-img/39.png" class="img-fluid">
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title text-right">{{ $t('dashboard.program') }}:
              <b>{{ program }}</b>
            </h4>
          </template>
          <div :style="`background: url(${require('../../assets/images/page-img/40.png')}) no-repeat scroll bottom; background-size: contain; height: 367px;`">
          </div>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dashboard.last_users') }}</h4>
          </template>
          <template v-slot:body>
            <ul class="perfomer-lists m-0 p-0">
              <li class="d-flex mb-4 align-items-center" v-for="item in lastUsers" :key="item.name">
                <div class="user-img img-fluid"><img :src="item.logo || require('../../assets/images/user/user-01.jpg')" alt="story-img" class="rounded-circle avatar-40"></div>
                <div class="media-support-info ml-3">
                  <h6>{{ item.name }}</h6>
                  <p class="mb-0 font-size-12">{{ item.created_at }}</p>
                </div>
              </li>
            </ul>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:body>
            <h6>{{ $t('dashboard.total_bookings') }}</h6>
            <h2>{{ totalBookings }}</h2>
          </template>
          <div id="chart-1">
            <ApexChart element="chart-1" :chartOption="chart1"/>
          </div>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <h6>{{ $t('dashboard.total_revenue') }}</h6>
            <h2>{{ totalRevenue }}</h2>
          </template>
          <div id="chart-2">
            <ApexChart element="chart-2" :chartOption="chart2"/>
          </div>
        </iq-card>
      </b-col>
      <b-col lg="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dashboard.recent_bookings') }}</h4>
          </template>
          <template v-slot:body>
            <table class="table mb-0 table-borderless">
              <thead>
              <tr>
                <th scope="col">{{ $t('customer.customer_name') }}</th>
                <th scope="col">{{ $t('dashboard.booking_date') }}</th>
                <th scope="col">{{ $t('dashboard.booking_price') }}</th>
                <th scope="col">{{ $t('dashboard.booking_status') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in lastBookings" :key="item.theDate">
                <td>{{ item.customer }}<br>
                  <small>{{ item.theTime }}</small>
                </td>
                <td>{{ item.theDate }}</td>
                <td>{{ item.price }}</td>
                <td><div class="badge badge-pill badge-success">{{ item.status }}</div></td>
              </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dashboard.notifications') }}</h4>
          </template>
          <template v-slot:body>
            <ul class="iq-timeline">
              <li v-for="item in lastMessages" :key="item.created_at">
                <div class="timeline-dots"></div>
                <h6 class="float-left mb-1">{{ item.from }}</h6>
                <small class="float-right mt-1">{{ item.created_at }}</small>
                <div class="d-inline-block w-100">
                  <p>{{ item.message }}</p>
                </div>
              </li>
            </ul>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import ApexChart from '../../components/core/charts/ApexChart'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
export default {
  name: 'Dashboard6',
  mounted () {
    core.index()
    this.getThisMonthValues()
  },
  components: { ApexChart },
  data () {
    return {
      // api values
      apiToken: localStorage.getItem('api_token') || '',
      numberOfBookings: null,
      totalCash: null,
      totalCard: null,
      totalMoney: null,
      totalRevenue: null,
      totalBookings: null,
      program: null,
      lastUsers: [],
      lastBookings: [],
      lastMessages: [],
      chart1: {
        chart: {
          height: 90,
          type: 'area',
          sparkline: {
            enabled: true
          },
          group: 'sparklines'

        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0
          }
        },
        series: [{
          name: '',
          data: [60, 15, 50, 30, 70]
        }],
        colors: ['#827af3'],

        xaxis: {
          type: 'datetime',
          categories: ['2018-08-19T00:00:00', '2018-09-19T01:30:00', '2018-10-19T02:30:00', '2018-11-19T01:30:00', '2018-12-19T01:30:00']
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      },
      chart2: {
        chart: {
          height: 90,
          type: 'area',
          sparkline: {
            enabled: true
          },
          group: 'sparklines'

        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0
          }
        },
        series: [{
          name: '',
          data: [70, 40, 60, 30, 60]
        }],
        colors: ['#b777f3'],
        xaxis: {
          type: 'datetime',
          categories: ['2018-08-19T00:00:00', '2018-09-19T01:30:00', '2018-10-19T02:30:00', '2018-11-19T01:30:00', '2018-12-19T01:30:00']
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      },
      chart13: {
        chart: {
          height: 320,
          type: 'radialBar',
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 3,
            dashArray: 0
          }
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 10,
              size: '30%',
              background: 'transparent',
              image: undefined,
              imageWidth: 64,
              imageHeight: 64
            },
            dataLabels: {
              name: {
                fontSize: '22px'
              },
              value: {
                fontSize: '16px'
              },
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 249
                }
              }
            }
          }
        },
        fill: {
          type: 'gradient'
        },
        colors: ['#0084ff', '#00ca00', '#ffd400', '#b777f3'],
        series: [44, 55, 67, 80],
        stroke: {
          lineCap: 'round'
        },
        labels: [' Sales', 'Profit', 'Cost', 'Investment']
      }
    }
  },
  methods: {
    getThisMonthValues () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const userToken = {
        token: this.apiToken
      }
      Axios.post(API.API_GET_DASHBOARD, userToken)
        .then((response) => {
          if (response.data.status === 'success') {
            this.numberOfBookings = response.data.dashboard.numberOfBookings
            this.totalCash = response.data.dashboard.cashToPay
            this.totalCard = response.data.dashboard.cardToPay
            this.totalMoney = response.data.dashboard.totalPay
            this.lastUsers = response.data.dashboard.lastUsers
            this.lastBookings = response.data.dashboard.lastBookings
            this.lastMessages = response.data.dashboard.lastMessages
            this.totalRevenue = response.data.dashboard.totalRevenue
            this.totalBookings = response.data.dashboard.totalBookings
            this.program = response.data.dashboard.program === 'not set!' ? this.$t('dashboard.not_set') : response.data.dashboard.program
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>
